import { Injectable } from '@angular/core';

import { Vendor } from '../../models/vendor';

import { VendorDto } from './dto/vendor.dto';
import { IMapperFromDto } from './mappers';

/** Vendor mapper. */
@Injectable({
  providedIn: 'root',
})
export class VendorMapper implements IMapperFromDto<VendorDto, Vendor> {
  /** @inheritdoc */
  public fromDto(dto: VendorDto): Vendor {
    return {
      id: dto.vendor_id,
      name: dto.vendor_name,
      bio: dto.vendor_bio,
    };
  }
}
