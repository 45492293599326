<p *ngIf="cart !== null" class="message">
  <span
    *ngIf="
      cart.price.groceries < cart.price.minimumPriceOfOrder;
      else notMeetMinimumTemplate
    "
  >
    Your order does not meet the
    <pboxc-money [amount]="cart.price.minimumPriceOfOrder"></pboxc-money>
    minimum
  </span>

  <ng-template #notMeetMinimumTemplate>
    <span *ngIf="cart.price.amountLeftForFreeDelivery > 0">
      Just add
      <pboxc-money
        [amount]="cart.price.amountLeftForFreeDelivery"
      ></pboxc-money>
      more for free delivery!
    </span>
  </ng-template>
</p>
