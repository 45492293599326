<div
  class="product"
  *ngIf="orderItemSubject | async as orderItem; else productSkeletonTemplate"
>
  <div class="product__header">
    <button
      type="button"
      class="product__button"
      [class.product__button_expanded]="expanded"
      [attr.aria-expanded]="expanded"
      aria-label="Expand to see items inside"
      (click)="expanded = !expanded"
      [disabled]="!orderItem.product.isComposable"
    >
      <mat-icon
        *ngIf="orderItem.product.isComposable"
        class="product__expand-icon"
        aria-hidden
      >
        arrow_right
      </mat-icon>

      <pboxc-product-image
        *ngIf="withImages"
        class="product__image"
        [src]="orderItem.product.imageUrl"
      ></pboxc-product-image>

      <div class="product__heading">
        <span class="product__name" pboxcEllipsis>
          {{ orderItem.product.shortName }}
        </span>

        <div class="tags">
          <span
            class="tags__item tags__item_snap"
            *ngIf="orderItem.product.isSnap && appConfig.hasSnap"
          >
            SNAP
          </span>

          <span
            class="tags__item tags__item_with-every-order"
            *ngIf="orderItem.comesWithEveryOrder"
          >
            With Every Order
          </span>

          <button
            *ngIf="
              isCustomizationEnabled && isProductCustomizable(orderItem.product)
            "
            type="button"
            class="outlined outlined_thick with-icon customize-button"
            (click)="
              $event.stopPropagation();
              onCustomizeButtonClick(orderItem.product)
            "
          >
            <mat-icon
              svgIcon="customize"
              class="customize-button__icon"
            ></mat-icon>
            Customize
          </button>
        </div>
      </div>
    </button>

    <div class="product__slotted-content">
      <ng-container
        *ngIf="orderItemSlotTemplate"
        [ngTemplateOutlet]="orderItemSlotTemplate"
        [ngTemplateOutletContext]="{ $implicit: orderItem }"
      ></ng-container>
    </div>
  </div>

  <div class="product__body" *ngIf="expanded">
    <ng-container
      *ngIf="boxItems$ | async as boxItems; else boxItemsSkeletonTemplate"
    >
      <ul class="items-list" *ngIf="boxItems.length">
        <li *ngFor="let item of boxItems; trackBy: trackItem" class="item">
          <pboxc-product-image [src]="item.imageUrl" class="item__image">
          </pboxc-product-image>
          <span pboxcEllipsis class="item__title">
            {{ item.name }}
          </span>
        </li>
      </ul>
    </ng-container>
  </div>
</div>

<ng-template #productSkeletonTemplate>
  <div class="product">
    <div class="product__header">
      <div class="product__button">
        <div class="product__image" *ngIf="withImages" pboxcSkeleton></div>
        <div class="product__heading">
          <span
            class="product__name"
            pboxcSkeleton
            [pboxcSkeletonMinWidth]="10"
            [pboxcSkeletonMaxWidth]="15"
          ></span>
          <div class="tags">
            <span
              class="tags__item"
              pboxcSkeleton
              [pboxcSkeletonMinWidth]="3"
              [pboxcSkeletonMaxWidth]="6"
            ></span>
          </div>
        </div>
        <div class="product__slotted-content">
          <ng-container
            *ngIf="orderItemSlotSkeletonTemplate"
            [ngTemplateOutlet]="orderItemSlotSkeletonTemplate"
            [ngTemplateOutletContext]="{ $implicit: orderItem }"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #boxItemsSkeletonTemplate>
  <div class="items-list">
    <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
    <div class="item" *ngFor="let _ of [1, 2, 3]">
      <div class="item__image" pboxcSkeleton></div>
      <span
        class="item__title"
        pboxcSkeleton
        [pboxcSkeletonMinWidth]="10"
        [pboxcSkeletonMaxWidth]="20"
      ></span>
    </div>
  </div>
</ng-template>
